<template>
  <b-container
  fluid>
    <b-row>
      <b-col cols="12">
				<div
				:class="'response-panel d-flex ' + (smaMode ?
				'sidebar-margin fixNotchMarginBottom' :'fixNotchMarginBottom')">
					<div
					style="width: 100%;">
						<b-card>
							<b-card-text>
								<b-form-radio-group
								ref="group"
								v-if="qdef ? qdef.questionType === questionType.Q_TF_TYPE
									|| qdef.questionType === questionType.Q_MC_TYPE : false"
								class="multiple-answer-panel d-flex"
								v-model="answerMC"
								:options="options"
								:disabled="disabled"
								@change="getAnswer()"/>

								<b-form-checkbox-group
								ref="group"
								v-if="qdef ? qdef.questionType === questionType.Q_MR_TYPE : false"
								class="multiple-answer-panel d-flex"
								v-model="answerMR"
								:options="options"
								:disabled="disabled"
								@change="getAnswer()"/>

								<b-form-input
								ref="input"
								v-if="qdef ? qdef.questionType === questionType.Q_NR_TYPE : false"
								v-model="answerNR"
								autocomplete="off"
								:placeholder="$t('response.placeholder')"
								:disabled="disabled"
								:formatter="numericFormatter"
								@change="getAnswer()"/>

								<b-form-input
								ref="input"
								v-if="qdef ? qdef.questionType === questionType.Q_SA_TYPE : false"
								v-model="answerSA"
								autocomplete="off"
								:placeholder="$t('response.placeholder')"
								:disabled="disabled"
								@change="getAnswer()"/>
							</b-card-text>
						</b-card>
					</div>
					<div>
						<b-button
						v-if="$store.state.mode === mode.POLLING_SESSION"
						:disabled="disabled || !hasAnswer"
						class="circular-response-button"
                        :aria-label= "$t('tooltip.respond')"
						v-b-tooltip.hover="{ title: $t('tooltip.respond'), disabled: isMobile() }"
						@click="sendAnswer()">
							<i v-if="!$store.state.isSendingAnswer" class="fa-solid fa-paper-plane"></i>
							<i v-else class="fa-solid fa-circle-notch fa-spin"></i>
						</b-button>
						<b-button
						v-if="$store.state.mode === mode.ONLINE_TEST"
						:disabled="disabled || !hasAnswer"
						class="circular-response-button"
                        :aria-label= "$t('tooltip.save')"
						v-b-tooltip.hover="{ title: $t('tooltip.save'), disabled: isMobile() }"
						@click="saveAnswer()">
							<i class="fa-solid fa-floppy-disk"></i>
						</b-button>
					</div>
				</div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { QuestionType } from '@/components/common/constants';
import BaseComponent from '@/components/common/BaseComponent';

export default {
	name: 'ResponsePanel',
	mixins: [BaseComponent],
	props: ['disabled'],
	data() {
		return {
			qdef: null,
			options: [],
			answerMC: null,
			answerMR: [],
			answerNR: null,
			answerSA: null,
			ackId: null,
			answerCode: null,
			answerKey: null,
			answerText: null,
		};
	},
	computed: {
		hasAnswer() {
			return this.answerMC !== null
			|| this.answerMR.length > 0
			|| (this.answerNR && this.answerNR.trim().length > 0)
			|| (this.answerSA && this.answerSA.trim().length > 0);
		},
		hasQDefAnswer() {
			return this.qdef !== null
				&& typeof this.qdef.givenAnswer !== 'undefined'
				&& this.qdef.givenAnswer !== null
				&& this.qdef.givenAnswer !== '';
		},
		smaMode() {
			return this.$store.state.smaMode;
		}
	},
	methods: {
		numericFormatter(value) {
			let result = null;

			if (this.$i18n.locale === 'es') {
				result = /^-?\d*((,\d{0,14})|(\/-?\d*))?$/.test(value);
			} else {
				result = /^-?\d*((\.\d{0,14})|(\/-?\d*))?$/.test(value);
			}

			if (!result) {
				return value.substring(0, value.length - 1);
			}

			return value;
		},
		setCurrentQDef(qdef) {
			this.qdef = qdef;

			this.clearAnswer();

			if (!this.qdef) {
				return;
			}

			this.options = [];

			if (this.qdef.questionType === QuestionType.Q_TF_TYPE
				|| this.qdef.questionType === QuestionType.Q_MC_TYPE
				|| this.qdef.questionType === QuestionType.Q_MR_TYPE) {
				for (let i = 0; i < this.qdef.answers.length; i += 1) {
					const answer = this.qdef.answers[i];
					const option = {
						text: this.getAnswerText(answer.at),
						value: { text: this.getAnswerText(answer.at), key: answer.akey }
					};
					this.options.push(option);
				}
			}

			this.$nextTick(() => {
				this.setFocus();
			});
		},
		setFocus() {
			switch (this.qdef.questionType) {
			case QuestionType.Q_TF_TYPE:
			case QuestionType.Q_MC_TYPE:
			case QuestionType.Q_MR_TYPE:
				this.$refs.group.tabindex = -1;
				setTimeout(() => {
					this.$refs.group.tabindex = 0;
					this.$refs.group.$children[0].$el.childNodes[0].focus();
				}, 200);
				break;
			case QuestionType.Q_NR_TYPE:
			case QuestionType.Q_SA_TYPE:
                if (!this.isMobile()) {
                    this.$refs.input.$el.focus();
                }
				break;
			default:
				break;
			}
		},
		hasPendingAnswer() {
			return this.hasAnswer && (!this.hasQDefAnswer
				|| (this.answerKey && this.answerKey !== this.qdef.givenAnswer));
		},
		sendAnswer() {
			if (this.$store.state.isSendingAnswer) {
				return;
			}

			if (!this.qdef.allowMultipleChances) {
				if (this.hasQDefAnswer) {
					return;
				}
			}

			if (!this.answerKey) {
				return;
			}

			this.ackId = btoa(Math.random()).slice(0, 10); // generate random id

			this.answerCode = `ANS:${this.ackId}:${this.qdef.questionId}`;

			if (this.$store.state.sessionInfo.supportsAnswerConfirmation) {
				this.answerCode = `C${this.answerCode}`;
			}

			const data = {
				ackId: this.ackId,
				answerCode: this.answerCode,
				answerKey: this.answerKey,
				answerText: this.answerText
			};

			this.$emit('send-answer', data);
		},
		saveAnswer() {
			const data = {
				answerKey: this.answerKey,
				answerText: this.answerText
			};

			this.$emit('save-answer', data);
		},
		getAnswer() {
			switch (this.qdef.questionType) {
			case QuestionType.Q_TF_TYPE:
			case QuestionType.Q_MC_TYPE:
				this.answerText = this.answerMC.text;
				this.answerKey = this.answerMC.key;
				break;
			case QuestionType.Q_MR_TYPE:
				this.answerText = this.answerMR.map((x) => x.text).sort().join(', ');
				this.answerKey = this.answerMR.map((x) => x.key).sort().join('');
				break;
			case QuestionType.Q_NR_TYPE:
				this.answerNR = this.cleanNumericResponse(this.answerNR);
				if (this.answerNR.charAt(this.answerNR.length - 1) === ','
					|| this.answerNR.charAt(this.answerNR.length - 1) === '.') {
					this.answerNR = this.answerNR.substring(0, this.answerNR.length - 1);
				}
				this.answerText = this.answerNR;
				this.answerKey = this.answerNR;
				break;
			case QuestionType.Q_SA_TYPE:
				this.answerText = this.answerSA.trim();
				this.answerKey = this.answerSA.trim();
				break;
			default:
				break;
			}
		},
		setGivenAnswer() {
			switch (this.qdef.questionType) {
			case QuestionType.Q_TF_TYPE:
			case QuestionType.Q_MC_TYPE:
				this.answerMC = this.setAnswer(this.qdef.givenAnswer);
				break;
			case QuestionType.Q_MR_TYPE:
				this.answerMR = [];
				for (let i = 0; i < this.qdef.givenAnswer.length; i += 1) {
					const answerLetter = this.qdef.givenAnswer.charAt(i);
					this.answerMR.push(this.setAnswer(answerLetter));
				}
				break;
			case QuestionType.Q_NR_TYPE:
				this.answerNR = this.qdef.givenAnswer;
				break;
			case QuestionType.Q_SA_TYPE:
				this.answerSA = this.qdef.givenAnswer;
				break;
			default:
				break;
			}

			this.getAnswer();
		},
		setAnswer(answerLetter) {
			const index = this.qdef.answers.findIndex((x) => x.akey === answerLetter);
			if (index === -1) {
				return {};
			}
			const answer = this.qdef.answers[index];
			return { text: this.getAnswerText(answer.at), key: answer.akey };
		},
		cleanNumericResponse(value) {
			let result = null;

			if (this.$i18n.locale === 'es') {
				result = value.replaceAll(/[^\d,+-/]/g, '');
			} else {
				result = value.replaceAll(/[^\d.+-/]/g, '');
			}

			return result;
		},
		clearAnswer() {
			this.answerMC = null;
			this.answerMR = [];
			this.answerNR = null;
			this.answerSA = null;
			this.ackId = null;
			this.answerCode = null;
			this.answerKey = null;
			this.answerText = null;

			if (this.hasQDefAnswer) {
				this.setGivenAnswer();
			}

			this.$nextTick(() => {
				this.setFocus();
			});
		}
	},
};
</script>

<style scoped>
h2 {
    color: #D5006C;
    font-weight: 600;
}
h4 {
    font-size: 1.375em;
    font-weight: 600;
    color: #707070;
}
.card {
    min-height: 50px;
}
.card-body {
	margin: 0;
    padding: 5px;
}
.multiple-answer-panel.disabled {
    color: #707070;
}
.form-control:disabled {
    color: #707070;
}
@media only screen and (max-width: 1365px),
		only screen and (max-height: 820px) {
    .container-fluid {
    position: fixed;
    bottom: 10px;
    max-width: 100%;
    }
}

</style>
