<template>
  <div v-if="smaMode">
    <div ref="parent"
    :class="'d-none d-sm-block sidebar' +
    ($store.state.mode === mode. ONLINE_TEST ? ' online-test-sidebar' : '')">
			<b-container ref="child"
			fluid
			class="mb-0 p-0">
				<QuestionList ref="questionList"
				:tdef="tdef"
				@move-to="$emit('move-to', $event)"/>
			</b-container>
		</div>
        <b-button
			v-if="$store.state.mode === mode.ONLINE_TEST"
			class="submit"
			@click="$emit('submit-test')">
				<i class="fa-solid fa-paper-plane mr-2"></i>
				{{ $t('test-navbar.submit').toUpperCase() }}
			</b-button>
		<!-- Phone view -->
		<b-container>
			<b-row
			class="d-sm-none mt-1">
				<b-col cols="12" class="mb-2 mt-2">
					<div
					class="d-flex test-navigation">
						<b-link
						:disabled="isFirstQuestion"
						@click="$emit('move-prev')">
							<i class="fa-solid fa-circle-arrow-left"></i>
						</b-link>
            <div class="question-title">
              <b-link
              class="d-flex"
							@click="$emit('show-test-navigation')">
                <h3
								class="mb-0"
								style="font-weight: bold;">
									{{ `${currentQIndex + 1}/${numQuestions}` }} {{ qDef ? qDef.questionName : '?' }}
								</h3>
                <i class="fa-solid fa-chevron-down"></i>
              </b-link>
							<!--<span style="color: #D5006C; float: right;">
								{{ qDef ? getQuestionType(qDef.questionType) : '?' }}
							</span>-->
						</div>
						<b-link
						:disabled="isLastQuestion"
						@click="$emit('move-next')">
							<i class="fa-solid fa-circle-arrow-right"></i>
						</b-link>
					</div>
				</b-col>
			</b-row>
		</b-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/common/BaseComponent';
import QuestionList from '@/components/common/QuestionList.vue';

export default {
	name: 'TestNavBar',
	mixins: [BaseComponent],
	props: ['tdef'],
	components: {
		QuestionList
	},
	computed: {
		currentQIndex() {
			return this.$store.state.currentQIndex;
		},
		qDef() {
			return this.tdef.questions[this.currentQIndex];
		},
		numQuestions() {
			return this.tdef.questions.length;
		},
		isFirstQuestion() {
			return this.$store.state.currentQIndex <= 0;
		},
		isLastQuestion() {
			return this.$store.state.currentQIndex >= this.tdef.questions.length - 1;
		},
		smaMode() {
			return this.$store.state.smaMode;
		}
	},
	methods: {
		scrollToCurrentQDef() {
			if (!this.$refs || !this.$refs.questionList) {
				return;
			}
			this.$refs.questionList.scrollToCurrentQDef();
		}
	},
	watch: {
		tdef() {
			this.scrollToCurrentQDef();
		}
	}
};
</script>

<style scoped>
.test-navigation {
    align-items: center;
    justify-content: space-between;
}
.question-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.question-title a {
    align-items: center;
}
.question-title a:hover {
    text-decoration: none;
}
.question-title h3 {
    font-size: 1.2rem;
    /*text-decoration: underline;*/
}
.question-title a i {
    font-size: 1.2rem;
    margin-left: 8px;
}
.test-navigation i {
    font-size: 2rem;
}
a {
    display: block;
    color: #2A2522;
    text-decoration: none;
    padding: 0;
}
a.disabled {
    color: #D3D3D3;
}
button.submit {
	bottom: 0;
    position: absolute;
    width: 230px;
    margin: 10px 10px 0 10px;
	background-color: #E0E0E0 !important;
    border-color: #E0E0E0 !important;
    color: #2A2522 !important;
	z-index: 999;
}
button.submit:hover {
        background-color: #E0E0E0 !important;
}
@media only screen and (max-width: 1365px),
		only screen and (max-height: 820px) {
            button.submit {
                position: fixed;
                bottom: 15px;
            }
        }
@media only screen and (max-width: 576px) {
    button.submit {
            display: none;
        }
}
</style>
