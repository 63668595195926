<template>
  <div>
    <div class="help-container">
       <b-link
			:href="$store.state.helpUrl"
            :aria-label= "$t('tooltip.help')"
             target="_blank"
			v-b-tooltip.hover="{ title: $t('tooltip.help'), disabled: isMobile() }">
        <i class="fa-solid fa-circle-question"></i>
      </b-link>
    </div>
    <b-container class="mt-3 mt-md-6 main-container">
      <div style="max-width: 420px; margin: 0 auto;">
        <div class="ev-logo">
          <b-img src="img/logo-examview-blue.svg" alt="Examview Student"/>
        </div>
      </div>
      <div style="max-width: 503px; margin: 0 auto;">
        <h2 class="noselect">{{ $t('online-test.title') }}</h2>
        <b-card>
          <div style="padding: 1em;">
						<b-form id="downloadForm" @submit.stop.prevent="connect()">
							<div class="step-title-container mt-2">
                                 <i class="fa-solid fa-circle-1 fa-xl step-title-circle"></i>
								<h3 class="noselect">{{ $t('common.student-id') }}</h3>
							</div>
							<div style="padding: 0 15px;">
									<b-form-input
									ref="studentIdInput"
									v-model="studentId"
									:placeholder="$t('online-test.step-1.placeholder')"
									autocomplete="off"
									:disabled="showDownloadingMessage"
									@input="showStudentIdError = false"
									:class="showStudentIdError ? 'error' : ''"/>
								<b-form-invalid-feedback :force-show="showStudentIdError">
									{{ $t('common.invalid-student-id') }}
								</b-form-invalid-feedback>
							</div>
							<div class="step-title-container">
                                 <i class="fa-solid fa-circle-2 fa-xl step-title-circle"></i>
								<h3 class="noselect">{{ $t('common.online-test-id') }}</h3>
							</div>
							<div style="padding: 0 15px;">
								<div class="d-flex" style="align-items: center;">
									<b-form-input
									v-model="onlineTestId"
									:placeholder="$t('online-test.step-2.placeholder')"
									autocomplete="off"
									:disabled="showDownloadingMessage"
									@input="showOnlineTestIdError = false"
									:class="showOnlineTestIdError ? 'error' : ''"/>
								</div>
                                <div role="alert">
								<b-form-invalid-feedback :force-show="showOnlineTestIdError"
                                aria-live="assertive">
									{{ errorMessage }}
								</b-form-invalid-feedback>
                                </div>
								<b-form-valid-feedback :force-show="showDownloadingMessage">
									<p
									class="my-1">
										<span
										style="color: #707070;">
											{{ infoMessage }}
										</span>
									</p>
								</b-form-valid-feedback>
								<b-form-checkbox
								class="mt-4 mb-2"
								v-model="rememberInfo"
								@change="doRememberInfo()" switch>
									{{ $t('common.remember-info') }}
								</b-form-checkbox>
							</div>
						</b-form>
						<LocalOnlineTestList
						:data="localOnlineTests"
						:disabled="showDownloadingMessage"
						@remove-item="removeLocalOnlineTest($event)"
						@connect-item="processOnlineTest($event)"/>
          </div>
					<b-card-footer class="d-flex justify-content-end py-2">
						<b-button class="transparent-button mr-2" type="button" @click="$emit('back')">
							{{ $t('common.back') }}
						</b-button>
                        <b-button class="d-flex" type="submit" form="downloadForm"
									v-b-tooltip.hover="{ title: $t('tooltip.download'), disabled: isMobile() }">
									<i v-if="showConnectingMessage" class="fa-solid fa-circle-notch fa-spin mr-1"></i>
                                    {{ $t('tooltip.next') }}
						</b-button>
					</b-card-footer>
        </b-card>
			</div>
		</b-container>

		<!-- LOCAL TEST FOUND Modal -->
		<b-modal
		id="local-test-modal"
		v-model="showLocalTestFoundModal"
		hide-header	no-close-on-esc
		@shown="$refs.ok.focus()">
			<div class="my-5 text-center">
				<p class="mb-3 noselect">{{ $t('online-test.test-stored') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="showLocalTestFoundModal = false">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="loadLocalTestOnline()">
					{{ $t('common.buttons.continue') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- DELETE LOCAL TEST Modal -->
		<b-modal
		id="delete-stored-test-modal"
		v-model="showDeleteLocalTestModal"
		hide-header	no-close-on-esc
		@shown="$refs.ok.focus()">
			<div class="mt-2 text-center">
				<i class="fa-solid fa-trash"></i>
				<p class="mb-3 noselect">{{ $t('online-test.want-delete-stored-test') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="doNotRemoveLocalOnlineTest()">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="doRemoveLocalOnlineTest()">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- PASSWORD Modal -->
		<b-modal
		id="test-password-modal"
		v-model="showPasswordModal"
        :header-close-label="$t('common.buttons.close')"
		:title="$t('online-test.test-protected')"
		@shown="setPasswordModalInputFocus()">
				<b-form id="passwordForm" @submit.stop.prevent="checkTestPassword()">
				<p class="mb-3 noselect">{{ $t('online-test.enter-provided-password') }}</p>
				<b-form-input
				ref="passwordInput"
				v-model="userPassword"
				:placeholder="$t('online-test.enter-password')"
				autocomplete="off"
				@input="showPasswordError = false"
				:class="showPasswordError ? 'error' : ''"/>
				</b-form>
			<template #modal-footer>
					<b-btn @click="showPasswordModal = false"
                        class="transparent-button mr-2">
						{{ $t('common.buttons.cancel') }}
					</b-btn>
					<b-btn type="submit" form="passwordForm"
						:disabled="!userPassword || userPassword.trim().length === 0">
						{{ $t('common.buttons.login') }}
					</b-btn>
			</template>
		</b-modal>

    <div
		class="version-text">
      <span class="noselect">{{ $store.state.deviceId }}</span>
      <span class="noselect">{{ $store.state.version }}</span>
    </div>
  </div>
</template>

<script>
import native from '@/api/native';
import api from '@/api/online-test';
import XmlUtil from '@/components/util/XmlUtil';
import BaseComponent from '@/components/common/BaseComponent';
import OnlineTestDefinition from '@/model/OnlineTestDefinition';
import LocalOnlineTestList from '@/components/LocalOnlineTestList.vue';

export default {
	name: 'OnlineTest',
	mixins: [BaseComponent],
	components: {
		LocalOnlineTestList
	},
	data() {
		return {
			studentId: null,
			onlineTestId: null,
			rememberInfo: false,
			isSavedData: false,
			localOnlineTests: [],
			localOnlineTestId: null,
			xmlTDef: null,
			userPassword: null,
			testPassword: null,
			infoMessage: null,
			errorMessage: null,
			showPasswordError: false,
			showStudentIdError: false,
			showOnlineTestIdError: false,
			showDownloadingMessage: false,
			response: null,
			numImagesDownloaded: 0,
			hasImageError: false,
			showLocalTestFoundModal: false,
			showDeleteLocalTestModal: false,
			showPasswordModal: false,
			checked: false,
			testDefinition: null
		};
	},
	methods: {
		reset() {
			this.rememberInfo = native.getItem('remember-info') === 'true';
			if (this.rememberInfo) {
				this.studentId = native.getItem('studentId');
				this.onlineTestId = native.getItem('onlineTestId');
			} else {
				native.removeItem('studentId');
				native.removeItem('onlineTestId');
				this.studentId = null;
				this.onlineTestId = null;
			}
			this.isSavedData = native.getItem('isSavedData');
			this.xmlTDef = null;
			this.showStudentIdError = false;
			this.showOnlineTestIdError = false;
			this.showDownloadingMessage = false;

			if (this.$store.state.isOnlineTestSent) {
				this.studentId = null;
				this.onlineTestId = null;
				native.removeItem('studentId');
				native.removeItem('onlineTestId');
				this.$store.commit('setIsOnlineTestSent', false);
			}

			this.localOnlineTests = api.getLocalOnlineTests();

			this.$nextTick(() => {
				if (!this.isMobile()) {
					this.$refs.studentIdInput.$el.focus();
				}
			});
		},
		setPasswordModalInputFocus() {
			if (!this.isMobile()) {
				this.$refs.passwordInput.$el.focus();
			}
		},
		validate() {
			this.showStudentIdError = !(this.studentId && this.studentId.trim().length > 0);
			this.showOnlineTestIdError = !(this.onlineTestId && /^[EV]|[Ev]|[eV]|[ev]-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}$/.test(this.onlineTestId));

			this.errorMessage = null;

			if (this.showOnlineTestIdError) {
				this.errorMessage = this.$t('online-test.invalid-test-id');
			}

			if (this.onlineTestId) {
				this.onlineTestId = this.onlineTestId.toUpperCase();
			}

			return !this.showStudentIdError && !this.showOnlineTestIdError;
		},
		connect() {
			if (this.showDownloadingMessage || !this.validate()) {
				return;
			}

			this.$store.commit('setStudentId', this.studentId);
			this.$store.commit('setOnlineTestId', this.onlineTestId);

			this.saveInfo();

			this.xmlTDef = api.getLocalOnlineTest(this.onlineTestId);

			if (this.xmlTDef) {
				this.showLocalTestFoundModal = true;
				return;
			}

			this.downloadTestOnline();
		},
		doRememberInfo() {
			native.setItem('remember-info', this.rememberInfo);
		},
		saveInfo() {
			if (this.rememberInfo) {
				native.setItem('studentId', this.studentId.toUpperCase());
				native.setItem('onlineTestId', this.onlineTestId);
			} else {
				native.removeItem('studentId');
				native.removeItem('onlineTestId');
			}
		},
		removeLocalOnlineTest(onlineTestId) {
			this.localOnlineTestId = onlineTestId;
			this.showDeleteLocalTestModal = true;
		},
		doRemoveLocalOnlineTest() {
			api.removeLocalOnlineTest(this.localOnlineTestId);
			this.localOnlineTests = api.getLocalOnlineTests();
			this.showDeleteLocalTestModal = false;
			this.localOnlineTestId = null;
		},
		doNotRemoveLocalOnlineTest() {
			this.showDeleteLocalTestModal = false;
			this.localOnlineTestId = null;
		},
		processOnlineTest(onlineTest) {
			this.onlineTestId = onlineTest.id;

			if (this.showDownloadingMessage || !this.validate()) {
				return;
			}

			this.$store.commit('setStudentId', this.studentId);
			this.$store.commit('setOnlineTestId', this.onlineTestId);

			this.saveInfo();

			this.onlineTestId = onlineTest.id;
			this.xmlTDef = onlineTest.xml;

			this.loadLocalTestOnline();
		},
		loadLocalTestOnline() {
			this.showLocalTestFoundModal = false;

			this.testPassword = api.getLocalOnlineTestPassword(this.onlineTestId);

			if (this.testPassword) {
				this.processResponse('PASSWORD NEEDED');
				return;
			}

			this.doLoadLocalTestOnline();
		},
		doLoadLocalTestOnline() {
			// this.$store.commit('setOnlineTestId', this.onlineTestId);
			this.$store.commit('setCurrentTDef', new OnlineTestDefinition(this.xmlTDef).testDefinition);
			this.$router.push({ name: 'Main' });
		},
		downloadTestOnline() {
			this.infoMessage = this.$t('online-test.downloading');
			this.showDownloadingMessage = true;

			api.getOnlineTest(this.onlineTestId, this.studentId, this.$store.state.deviceId,
				(data) => {
					this.showDownloadingMessage = false;

					if (data && data !== 'ERROR') {
						this.response = data;
						this.processResponse(XmlUtil.getValue(data, 'status'));
					} else {
						this.errorMessage = this.$t('online-test.download-error');
						this.showOnlineTestIdError = true;
					}
				},
				() => {
					this.showDownloadingMessage = false;
					this.errorMessage = this.$t('online-test.download-error');
					this.showOnlineTestIdError = true;
				});
		},
		processResponse(status) {
			if (status === 'OK') {
				this.showDownloadingMessage = true;
				// this.$store.commit('setOnlineTestId', this.onlineTestId);
				this.testDefinition = new OnlineTestDefinition(this.response).testDefinition;
				this.downloadImages();
				return;
			}

			if (status === 'PASSWORD NEEDED') {
				this.userPassword = null;
				this.showPasswordModal = true;
				return;
			}

			switch (status) {
			case 'INVALID HOMEWORK ID':
				this.errorMessage = this.$t('online-test.incorrect-id');
				break;
			case 'ALREADY ANSWERED':
				this.errorMessage = this.$t('online-test.answered');
				break;
			case 'EXPIRED HOMEWORK':
				this.errorMessage = this.$t('online-test.expired');
				break;
			case 'START HOMEWORK':
				this.errorMessage = this.$t('online-test.not-available');
				break;
			case 'PASSWORD ERROR':
				this.errorMessage = this.$t('online-test.password-error');
				break;
			case 'INVALID STUDENT ID':
				this.errorMessage = this.$t('online-test.student-error');
				break;
			default:
				this.errorMessage = this.$t('online-test.default-error');
				break;
			}

			this.showOnlineTestIdError = true;
		},
		checkTestPassword() {
			this.showDownloadingMessage = true;
			this.showPasswordModal = false;

			if (this.testPassword) {
				if (this.userPassword === this.testPassword) {
					this.doLoadLocalTestOnline();
					this.testPassword = null;
				} else {
					this.showDownloadingMessage = false;
					this.processResponse('PASSWORD ERROR');
				}
				return;
			}

			// eslint-disable-next-line max-len
			api.getOnlineTestProtected(this.onlineTestId, this.studentId, this.userPassword,
				(data) => {
					this.showDownloadingMessage = false;

					if (data && data !== 'ERROR') {
						this.processResponse(XmlUtil.getValue(data, 'status'));
					} else {
						this.errorMessage = this.$t('online-test.download-error');
						this.showOnlineTestIdError = true;
					}
				},
				() => {
					this.showDownloadingMessage = false;
					this.errorMessage = this.$t('online-test.download-error');
					this.showOnlineTestIdError = true;
				});
		},
		downloadImages() {
			this.hasImageError = false;
			this.numImagesDownloaded = 0;
			const numImagesTotal = this.testDefinition.getNumImages();

			if (numImagesTotal === 0) {
				this.finishDownloadImages();
				return;
			}

			const me = this;

			for (let i = 0; i < this.testDefinition.questions.length; i += 1)	{
				const q = this.testDefinition.questions[i];

				for (let j = 0; j < q.urlImages.length; j += 1) {
					const urlImage = q.urlImages[j];

					XmlUtil.getBase64Image(urlImage, (base64Image) => {
						this.addBase64Image(q, urlImage, base64Image, numImagesTotal, () => {
							me.finishDownloadImages();
						},
						() => {
							this.testDefinition = new OnlineTestDefinition(this.response).testDefinition;
							api.removeOnlineTestImagesLocally();
							me.finishDownloadImages();
						});
					});
				}
			}
		},
		addBase64Image(question, urlImage, base64Image, numImagesTotal, cbok, cberr) {
			this.numImagesDownloaded += 1;

			if (this.hasImageError) {
				return;
			}

			try {
				const imageKey = `evs-ot-img-${this.$store.state.onlineTestId}@${this.numImagesDownloaded.toString().padStart(5, '0')}`;

				native.setItem(imageKey, base64Image);

				question.questionContent = question.questionContent.replace(urlImage, imageKey);
			} catch (e) {
				this.hasImageError = true;
				console.log(`*** localStorage error: [ code: ${e.code} - message: '${e.message}' ]`);
				cberr();
			}

			this.infoMessage = this.$t('online-test.downloading-images', [this.numImagesDownloaded, numImagesTotal]);

			if (this.numImagesDownloaded === numImagesTotal) {
				cbok();
			}
		},
		finishDownloadImages() {
			this.showDownloadingMessage = false;
			this.$store.commit('setCurrentTDef', this.testDefinition);
			api.saveOnlineTestLocally(this.userPassword);
			this.$router.push({ name: 'Main' });
		},
		handleBackButton() {
			if (this.showLocalTestFoundModal) {
				this.showLocalTestFoundModal = false;
				this.$log.debug('showLocalTestFoundModal is open!');
				return true;
			}
			if (this.showDeleteLocalTestModal) {
				this.doNotRemoveLocalOnlineTest();
				this.$log.debug('showDeleteLocalTestModal is open!');
				return true;
			}
			if (this.showPasswordModal) {
				this.showPasswordModal = false;
				this.$log.debug('showPasswordModal is open!');
				return true;
			}
			this.$log.debug('No modal is open!');
			return false;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
h1 {
  margin: 40px 0 0;
  color: #D5006C;
}
.card {
    margin: 15px auto;
    min-height: auto;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    z-index: 2;
}
.card-body {
    padding: 0;
}
.card-text {
    padding: 1rem;
}
.card-footer {
    padding: 0.25rem 1.25rem;
    background-color: #FFFFFF;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0 0 10px 10px !important;
}
.form-control {
    font-size: 0.875rem;
    height: 42px;
}
.form-control.error {
	border-color: #FF5252;
}
.modal i {
    font-size: 5rem;
    color: #D5006C;
    margin-bottom: 20px;
}

</style>
