<template>
  <div v-if="show">
    <!-- DOWNLOAD INSTALLER MOBILE -->
		<b-card v-if="isMobile()" class="downloader-card-phone d-block d-lg-none">
			<button type="button" aria-label="Close" class="close" @click="show = false">×</button>
			<b-media vertical-align="top">
				<template #aside>
					<b-img
					src="img/app-icon.svg"
					width="80" alt="Examview Student"></b-img>
				</template>
				<h5 class="mt-0 mb-0">Examview Student</h5>
				<p class="mb-1 downloader-text">
					<a :href="getUrl()" target="_blank">
						{{ $t('download-installer.click-here') }}
					</a> {{ $t('download-installer.click-here-app') }}
				</p>
			</b-media>
		</b-card>
    <!-- DOWNLOAD INSTALLER DESKTOP -->
		<b-card class="downloader-card d-none d-lg-block" aria-labelledby="descripcion"
            no-body>
            <div class="d-flex justify-content-between align-items-center px-3 py-2 border-bottom">
                <h5 class="m-0">Examview Student</h5>
                <button type="button" aria-label="Close" class="close"
                @click="show = false">
                    ×
                </button>
            </div>
			<b-media vertical-align="top" class="p-3">
				<template #aside>
					<b-img
					src="img/app-icon.svg"
					width="100" alt="Examview Student"></b-img>
				</template>
				<p class="mb-1 downloader-text">
					<a :href="getUrl()" target="_blank">
						{{ $t('download-installer.click-here') }}
					</a> {{ $t('download-installer.click-here-desktop', { 0: getOS() }) }}
				</p>
				<p id="descripcion">{{ $t('download-installer.recommendation') }}</p>
				<b-link v-b-modal.modal-installers
				class="mt-1 text-right other-downloader-link" @click="showOtherPlatformsPopup()">
					{{ $t('download-installer.other-platforms') }}
				</b-link>
			</b-media>
		</b-card>
  </div>
</template>

<script>
import downloadLinks from '@/download-links';
import native, { Platform, OS } from '@/api/native';
import BaseComponent from '@/components/common/BaseComponent';

export default {
	name: 'DownloadInstallerCard',
	mixins: [BaseComponent],
	data() {
		return {
			show: false,
		};
	},
	methods: {
		mustBeShown() {
			if (!this.$store.state.showDownloadInstallerCard) {
				this.show = false;
				return;
			}

			if (native.getPlatform() === Platform.WEB) {
				switch (native.getOS()) {
				case OS.MACOS:
				case OS.WINDOWS:
				case OS.IOS:
				case OS.ANDROID:
					this.show = true;
					break;
				default:
					break;
				}
			}

			this.$store.commit('setShowDownloadInstallerCard', false);
		},
		getUrl() {
			let url = '#';

			switch (native.getOS()) {
			case OS.MACOS:
				url = downloadLinks.macos;
				break;
			case OS.WINDOWS:
				url = downloadLinks.windows;
				break;
			case OS.IOS:
				url = downloadLinks.ios;
				break;
			case OS.ANDROID:
				url = downloadLinks.android;
				break;
			default:
				break;
			}

			return url;
		},
		getOS() {
			let os = '???';

			switch (native.getOS()) {
			case OS.MACOS:
				os = 'MacOS';
				break;
			case OS.WINDOWS:
				os = 'Windows';
				break;
			case OS.IOS:
				os = 'iOS';
				break;
			case OS.ANDROID:
				os = 'Android';
				break;
			default:
				break;
			}

			return os;
		},
		showOtherPlatformsPopup() {
			this.show = false;
			this.$emit('show-other-platforms');
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
    font-size: 2.1rem;
    font-weight: 700;
}
h3 {
    width: 40%;
    font-weight: bold;
}
.card a {
    text-decoration: underline;
    padding: 0;
    display: inline-flex;
}
.card a:hover {
    text-decoration: none;
}
.card {
    max-width: 470px;
    margin: 0 auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #E5E5E5;
}
.card-img {
    border-radius: 10px;
    opacity: 0.9;
}
.card-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card p {
    font-size: 0.75rem;
    font-weight: 400;
}
.close {
    /* float: right; */
    font-size: 1.7rem !important;
    font-weight: 700 !important;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: transparent !important;
    border-radius: 0;
    padding: 0;
    /* position: relative; */
    /* top: -10px;
    left: 5px; */
}
.downloader-card {
    animation-name: downloader;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 1;
}
@keyframes downloader {
  from {top: 250px; opacity: 0;}
  to {top: 30px; opacity: 1;}
}
.downloader-card-phone {
    position: absolute;
    width: 100%;
    max-width: 100%;
    z-index: 9;
    top: 0;
    animation-name: downloader-app;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 1;
}
@keyframes downloader-app {
  from {right: 100%; opacity: 0;}
  to {right: 0%; opacity: 1;}
}
p.downloader-text {
    color: #58595B;
    font-size: 0.875rem;
}
.other-downloader-link {
    display: block !important;
    color: #231F20;
    font-weight: 400;
    font-size: 0.875rem;
}
@media only screen and (max-width: 1365px),
only screen and (max-height: 820px) and (-webkit-max-device-pixel-ratio: 2) {
    .downloader-card {
       height: 177px;
       position: absolute;
       bottom: 34px;
       margin: 0 auto;
       left: 10%;
       right: 10%;
       animation-name: downloader-phone;
       animation-duration: 2s;
       animation-fill-mode: forwards;
       opacity: 1;
    }
    @keyframes downloader-phone {
      from {bottom: -200px; opacity: 0;}
      to {bottom: 20px; opacity: 1;}
    }
}
@media only screen and (max-width: 360px) {
    h3 {
        width: 100%;
    }
    .card-body {
        padding: 1.2rem 1rem;
    }
}
.mode-selector-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.mode-selector-text-content {
    height: 190px;
    /*padding-left: 180px;*/
    background-color: #FFFFFF;
    position: relative;
    left: 60px;
    border-radius: 0 100px 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
}
.mode-selector-text-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.mode-selector-img {
    width: 210px;
    height: 210px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
}
@media only screen and (max-width: 576px) {
    .mode-selector-text-content p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-left: -60px;
    }
    .mode-selector-img {
        width: 180px;
        height: 180px;
    }
    .outlined-button {
        margin-left: -75px;
    }
}
</style>
