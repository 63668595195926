/* eslint-disable no-mixed-spaces-and-tabs */
	<template>
  <div :class="$store.state.isSendingAnswer ? 'full-disabled' : ''">
		<b-navbar
		class="ev-navbar"
		type="light"
		variant="white">
			<b-navbar-brand>
				<b-img
				class="mb-1"
				src="img/logo-examview-blue.svg"
				alt="Examview Student"
				width="120px">
				</b-img>
			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<!-- Only Phone Components -->
					<b-nav-item
					class="ev-phoneComponents-header">
						<div
						class="ev-goBack-icon-header"
						@click="showExitModal = true">
							<i class="fa-solid fa-person-to-door"></i>
						</div>
						<div>
							<div
							class="d-flex pt-2"
							style="align-items: center;">
								<h3
								v-if="$store.state.mode === mode.POLLING_SESSION"
								class="ev-label-sessionID ml-2 mb-0 noselect">
									{{ $store.state.sessionId }}
								</h3>
								<h3
								v-if="$store.state.mode === mode.ONLINE_TEST"
								class="ev-label-sessionID ml-2 mb-0 noselect">
									{{ $store.state.onlineTestId }}
								</h3>
									<i
									:class="networkIconClass + ' ml-2'"
									style="color: #9E9E9E;"></i>
									<i
									style="margin-left: 5px; color: #FFC107;"
									v-if="networkQualityStatus.level === networkQuality.WARNING_QUALITY"
									class="fa-solid fa-triangle-exclamation"/>
							</div>
							<label
							class="ev-label-studentID ml-2">
								{{ $t('common.student-id') }}: {{ $store.state.studentId }}
							</label>
						</div>
					</b-nav-item>
                    <b-nav-item
					class="divisor ml-2 mr-2">
                    </b-nav-item>
					<b-nav-item
					class="ev-connectionType-header">
						<i :class="networkIconClass + ' fa-xl'"></i>
						<i
						style="margin-left: 5px; color: #FFC107;"
						v-if="networkQualityStatus.level === networkQuality.WARNING_QUALITY"
						class="fa-solid fa-triangle-exclamation"/>
					</b-nav-item>
				</b-navbar-nav>

				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto">
					<b-nav-item class="id-labels-header">
						<label
						v-if="$store.state.mode === mode.POLLING_SESSION"
						class="ev-label-sessionID">
							{{ $t('common.session-id') }}: {{ $store.state.sessionId }}
						</label>
						<label
						v-if="$store.state.mode === mode.ONLINE_TEST"
						class="ev-label-sessionID">
							{{ $t('common.online-test-id') }}: {{ $store.state.onlineTestId }}
						</label>
						<label class="ev-label-studentID">
							{{ $t('common.student-id')}}: {{ $store.state.studentId }}
						</label>
					</b-nav-item>
                    <b-nav-item
					v-if="$store.state.mode === mode.POLLING_SESSION"
					class="divisor"></b-nav-item>
					<b-nav-item
					v-if="$store.state.mode === mode.POLLING_SESSION"
					class="ev-reload-header-icon"
          :aria-label= "$t('tooltip.refresh')"
          v-b-tooltip.hover="{ title: $t('tooltip.refresh'), disabled: isMobile(),
					id: 'refresh-tooltip' }"
					:disabled="disableRefresh"
					@click="doRefresh()">
						<i class="fa-solid fa-arrow-rotate-right"></i>
					</b-nav-item>
					<!-- Submit Test Link -->
					<b-nav-item
					v-if="$store.state.mode === mode.ONLINE_TEST"
					class="ev-phoneComponents-header">
						<b-link
						class="pr-0"
						@click="showSubmitTestModal = true">
								<i class="fa-solid fa-paper-plane mr-1"></i>
								{{ $t('main.submit-test') }}
						</b-link>
					</b-nav-item>

					<b-nav-item
					class="divisor"></b-nav-item>
					<b-nav-item
							class="ev-exit-header-icon pr-0 p-0"
                                :aria-label= "$t('tooltip.leave')"
							v-b-tooltip.hover="{ title: $t('tooltip.leave'), disabled: isMobile() }"
							@click="showExitModal = true">
								<i class="fa-solid fa-person-to-door"></i>
							</b-nav-item>
                            </b-navbar-nav>
			</b-collapse>
		</b-navbar>

		<div
		class="ev-main-content-container">
			<Welcome
			v-show="showWelcome"
			:info="welcomeInfo"/>
			<TestNavBar
			ref="tb"
			v-show="!showWelcome && currentTDef"
			:tdef="currentTDef"
			@move-to="moveToQDef($event)"
			@move-prev="movePrevQDef()"
			@move-next="moveNextQDef()"
			@show-test-navigation="showTestNavigationPopup()"
			@submit-test="showSubmitTestModal = true"/>
			<Question
			ref="q"
			v-show="!showWelcome && currentQDef"
			:qdef="currentQDef"/>
			<ResponsePanel
			ref="rp"
			v-show="!showWelcome && currentQDef"
			:disabled="disableResponsePanel"
			@send-answer="sendAnswer($event)"
			@save-answer="saveAnswer($event)"/>
		</div>

		<!-- EXIT Modal -->
		<b-modal
		id="exit-modal"
		v-model="showExitModal"
		no-close-on-esc :title="$t('main.exit-test')"
		@shown="$refs.ok.focus()">
			<div class="mt-2 text-center">
                <div class="exit-icon-container d-flex
                    align-items-center justify-content-center m-auto">
				<i class="fa-solid fa-person-to-door m-auto"></i>
                </div>
				<p
				v-if="$store.state.mode === mode.POLLING_SESSION"
				class="my-3 noselect">
					{{ $t('main.want-leave-session') }}
				</p>
				<p
				v-if="$store.state.mode === mode.ONLINE_TEST"
				class="my-3 noselect">
					{{ $t('main.want-leave-test') }}
				</p>
			</div>
			<template #modal-footer>
				<b-btn
				v-if="$store.state.mode === mode.POLLING_SESSION"
				@click="showExitModal = false" class="transparent-button mr-2">
					{{ $t('common.buttons.stay') }}
				</b-btn>
				<b-btn
				ref="ok"
				v-if="$store.state.mode === mode.POLLING_SESSION"
                :aria-label= "$t('common.buttons.leave')"
				@click="doExit()">
					{{ $t('common.buttons.leave') }}
				</b-btn>
				<b-btn
				v-if="$store.state.mode === mode.ONLINE_TEST"
				@click="showExitModal = false"
                class="transparent-button mr-2">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				v-if="$store.state.mode === mode.ONLINE_TEST"
				@click="confirmDiscardOnlineTest()"
                class="transparent-button mr-2">
					{{ $t('main.discard-test') }}
				</b-btn>
				<b-btn
				ref="ok"
				v-if="$store.state.mode === mode.ONLINE_TEST"
				@click="confirmSaveLocallyOnlineTest()">
					{{ $t('main.save-test-locally') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- SFINISHED Modal -->
		<b-modal
		id="sfinished-modal"
		v-model="showSFinishedModal"
		hide-header no-close-on-esc
		@shown="$refs.ok.focus()">
			<div class="mt-2 text-center">
				<i class="fa-solid fa-users-slash"></i>
				<p class="mb-3 noselect">{{ $t('main.session-finished') }}</p>
			</div>
			<template #modal-footer>
				<div/>
				<b-btn
				ref="ok"
				variant="primary"
				@click="doExit()">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- CONFIRM ANSWER Modal -->
		<b-modal
		id="confirm-answer-modal"
		v-model="showConfirmAnswerModal"
		no-close-on-esc :title="$t('main.save-answer')"
		@shown="$refs.ok.focus()">
			<div class="my-3 text-center">
				<p
				v-if="$store.state.mode === mode.POLLING_SESSION"
				class="mb-3 noselect">
					{{ $t('main.want-save-answer') }}
				</p>
				<p
				v-if="$store.state.mode === mode.ONLINE_TEST"
				class="mb-3 noselect">
					{{ $t('main.want-save-answer') }}
				</p>
				<h3 class="noselect">{{ currentAnswer ? currentAnswer.answerText : '' }}</h3>
			</div>
			<template #modal-footer>
				<b-btn @click="doNotSendAnswer()" class="transparent-button mr-1">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				v-if="$store.state.mode === mode.POLLING_SESSION"
				@click="doSendAnswer()">
					{{ $t('common.buttons.save') }}
				</b-btn>
				<b-btn
				ref="ok"
				v-if="$store.state.mode === mode.ONLINE_TEST"
				@click="doSaveAnswer()">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- PENDING ANSWER Modal -->
		<b-modal
		id="pending-answer-modal"
		v-model="showPendingAnswerModal"
		no-close-on-esc :title="$t('main.pending-answer-title')"
		@shown="$refs.ok.focus()">
			<div class="my-5 text-center">
				<p class="mb-3 noselect">{{ $t('main.pending-answer') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="continuePendingAnswer()" class="transparent-button mr-2">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="discardPendingAnswer()">
					{{ $t('common.buttons.continue') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- TEST NAVIGATION Modal -->
        <b-modal
		id="test-navigation-modal"
        v-model="showTestNavigationModal"
		centered hide-header hide-footer no-close-on-esc
		@shown="$refs.ok.focus()">
			<div class="questions-container position-relative">
                <div class="d-flex align-items-center p-3 border-bottom sticky-top bg-white">
                    <b-btn
                        ref="ok" class="close-icon-button mr-3 d-flex my-auto"
                        @click="showTestNavigationModal = false">
                           <i class="fa-solid fa-xmark my-auto"></i>
                    </b-btn>
                    <h4 class="noselect m-0" style="font-size: 1.1rem;">
                        {{ $t('main.test-questions') }}
                    </h4>
                </div>
				<QuestionList
				ref="ql"
				:tdef="currentTDef"
				@move-to="moveToQDefFromTestNavigation($event)"/>
			</div>
		</b-modal>

		<!-- SUBMIT ONLINE TEST Modal -->
		<b-modal
		id="submit-test-modal"
		v-model="showSubmitTestModal"
		no-close-on-esc :title="$t('main.submit-test')"
		@shown="$refs.ok.focus()">
			<div class="my-3 text-center">
				<div class="exit-icon-container d-flex
                    align-items-center justify-content-center m-auto">
				<i class="fa-solid fa-paper-plane m-auto"></i>
                </div>
				<p class="my-3 noselect">{{ $t('main.want-submit-test') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="showSubmitTestModal = false" class="transparent-button mr-2">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="submitOnlineTest()">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- CONFIRM DISCARD ONLINE TEST Modal -->
		<b-modal
		id="confirm-discard-test-modal"
		v-model="showConfirmDiscardTestModal"
		no-close-on-esc :title="$t('online-test.delete-test')"
		@shown="$refs.ok.focus()">
			<div class="my-3 text-center">
                <div class="exit-icon-container d-flex
                    align-items-center justify-content-center m-auto">
				<i class="fa-solid fa-trash-can m-auto"></i>
                </div>
				<p class="my-3 noselect">{{ $t('main.want-discard-test') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="showConfirmDiscardTestModal = false"
                class="transparent-button mr-2">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="discardOnlineTest()">
					{{ $t('common.buttons.continue') }}
				</b-btn>
			</template>
		</b-modal>

		<!-- CONFIRM SAVE LOCALLY ONLINE TEST Modal -->
		<b-modal
		id="confirm-save-test-modal"
		v-model="showConfirmSaveTestModal"
		no-close-on-esc :title="$t('main.save-test')"
		@shown="$refs.ok.focus()">
			<div class="my-3 text-center">
                <div class="exit-icon-container d-flex
                    align-items-center justify-content-center m-auto">
				<i class="fa-solid fa-floppy-disk m-auto"></i>
                </div>
				<p class="my-3 noselect">{{ $t('main.want-save-test-locally') }}</p>
			</div>
			<template #modal-footer>
				<b-btn
				@click="showConfirmSaveTestModal = false"
                class="transparent-button mr-2">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn
				ref="ok"
				variant="primary"
				@click="saveLocallyOnlineTest()">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>
  </div>
</template>

<script>
import api from '@/api/online-test';
import XmlUtil from '@/components/util/XmlUtil';
import QuestionUtil from '@/components/util/QuestionUtil';
import app2app from '@/api/app2app';
import native from '@/api/native';
import { NetworkQuality } from '@/components/common/constants';
import BaseComponent from '@/components/common/BaseComponent';
import Welcome from '@/components/Welcome.vue';
import TestNavBar from '@/components/TestNavBar.vue';
import QuestionList from '@/components/common/QuestionList.vue';
import Question from '@/components/Question.vue';
import a2aUtils from '@/model/A2AUtils';
import ResponsePanel from '@/components/ResponsePanel.vue';
import QuestionDefinition from '../model/QuestionDefinition';
import TestDefinition from '../model/TestDefinition';

export default {
	name: 'Main',
	mixins: [BaseComponent],
	components: {
		Welcome,
		TestNavBar,
		QuestionList,
		Question,
		ResponsePanel
	},
	data() {
		return {
			welcomeInfo: null,
			showWelcome: true,
			showExitModal: false,
			showSFinishedModal: false,
			disableRefresh: false,
			disableResponsePanel: false,
			isApp2AppMessagesRegistered: false,
			showConfirmAnswerModal: false,
			showPendingAnswerModal: false,
			futureQIndex: -1,
			currentAnswer: null,
			sendAnswerTimeoutId: null,
			showTestNavigationModal: false,
			showSubmitTestModal: false,
			showConfirmDiscardTestModal: false,
			showConfirmSaveTestModal: false,
			autosaveIntervalId: null,
			networkQualityStatus: { level: NetworkQuality.OK_QUALITY, errors: 0 },
			networkIconClass: 'fa-solid fa-cloud'
		};
	},
	mounted() {
		this.registerNativeEvents();
		document.addEventListener('keydown', this.keyboardNavigation);
	},
	beforeDestroy() {
		this.unregisterNativeEvents();
		document.removeEventListener('keydown', this.keyboardNavigation);
	},
	computed: {
		currentQDef() {
			return this.$store.state.currentQDef;
		},
		currentTDef() {
			return this.$store.state.currentTDef;
		},
		currentQIndex() {
			return this.$store.state.currentQIndex;
		},
		smaMode() {
			return this.$store.state.smaMode;
		}
	},
	methods: {
		keyboardNavigation(event) {
			// keyCode = 38 -> arrow up
			if (event.keyCode === 38) {
				event.stopPropagation();
				event.preventDefault();
				if (!this.isMobile() && this.smaMode) {
					this.movePrevQDef();
				}
				return;
			}
			// keyCode = 40 -> arrow down
			if (event.keyCode === 40) {
				event.stopPropagation();
				event.preventDefault();
				if (!this.isMobile() && this.smaMode) {
					this.moveNextQDef();
				}
			}
		},
		registerNativeEvents() {
			if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				return;
			}

			native.getEventBus().$on('window-blur', () => {
				this.hideAllModals();
				this.futureQIndex = this.currentQIndex;
				this.post('DISCONNECT', '');
			});

			native.getEventBus().$on('window-focus', () => {
				this.hideAllModals();
				this.post('JOINTABLET', this.$store.state.studentId);
			});
		},
		unregisterNativeEvents() {
			if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				return;
			}

			native.getEventBus().$off('window-blur');
			native.getEventBus().$off('window-focus');
		},
		unregisterApp2AppMessages() {
			if (!this.isApp2AppMessagesRegistered) {
				return;
			}

			this.isApp2AppMessagesRegistered = false;

			this.$log.debug('Main - *** unregisterApp2AppMessages()');

			app2app.getEventBus().$off('A2AMessageInfo');
			app2app.getEventBus().$off('A2ANetworkQualityStatus');
			app2app.getEventBus().$off('A2AConnectMessage');
		},
		registerApp2AppMessages() {
			if (this.isApp2AppMessagesRegistered) {
				return;
			}

			this.isApp2AppMessagesRegistered = true;

			this.$log.debug('Main - *** registerApp2AppMessages()');

			app2app.getEventBus().$on('A2AMessageInfo', () => {
				this.processPendingApp2AppMessages();
			});

			app2app.getEventBus().$on('A2ANetworkQualityStatus', (quality) => {
				this.processNetworkQualityStatus(quality);
			});

			app2app.getEventBus().$on('A2AConnectMessage', (message) => {
				this.$log.debug(`A2AConnectMessage - ${message.message}`);
				this.processNetworkStatusUpdate(message.isLocalConnected, message.isRemoteConnected);
			});
		},
		updateNetworkStatus() {
			const status = app2app.getConnectionStatus();
			this.processNetworkStatusUpdate(status.isLocalConnected, status.isRemoteConnected);
		},
		processNetworkStatusUpdate(isLocalConnected, isRemoteConnected) {
			if (isLocalConnected) {
				this.networkIconClass = 'fa-solid fa-network-wired';
			} else if (isRemoteConnected) {
				this.networkIconClass = 'fa-solid fa-cloud';
			} else {
				this.networkIconClass = 'fa-solid fa-exclamation';
				this.disconnect();
				this.showSFinishedModal = true;
			}
		},
		processNetworkQualityStatus(quality) {
			if (this.networkQualityStatus.level === quality.level) {
				return;
			}

			this.$log.debug(`*** A2ANetworkQualityStatus --> level: '${quality.level}' - errors: ${quality.errors}`);

			this.networkQualityStatus = quality;

			switch (quality.level) {
			case NetworkQuality.WARNING_QUALITY:
				// eslint-disable-next-line no-case-declarations
				const vNodesMessage = this.$createElement('p', { class: 'mb-0' },
					[
						this.$createElement('i', { class: 'fa-solid fa-wifi-fair mr-2' }),
						this.$t('main.low-network-quality')
					]);

				this.showToast([vNodesMessage], 'warning');
				break;
			case NetworkQuality.ERROR_QUALITY:
				app2app.disconnect();
				this.unregisterApp2AppMessages();
				this.$router.push({ name: 'Home' });
				break;
			default:
				break;
			}
		},
		processPendingApp2AppMessages() {
			this.$log.debug('Main - *** processPendingApp2AppMessages()');

			let message = app2app.getPendingMessage();
			while (message) {
				this.processMessage(message);
				message = app2app.getPendingMessage();
			}
		},
		processMessage(message) {
			this.$log.debug(`Main - A2AMessageInfo - ${message.sourceUserId} --> ${message.messageType} : ${a2aUtils.safeDecode(message.messageContent)}`);

			switch (message.messageType) {
			case 'WELCOME':
				this.$store.commit('setStudentInfo', a2aUtils.safeDecode(message.messageContent));
				this.showWelcome = true;
				break;
			case 'QDEF':
				this.showCurrentQDef(new QuestionDefinition(message.messageContent));
				this.showWelcome = false;
				break;
			case 'ACF':
				if (this.currentAnswer.ackId !== message.messageContent) {
					this.disableResponsePanel = false;
					this.$log.debug(`*** ACK ID doesn't match! ${this.currentAnswer.ackId} <--> ${message.messageContent}`);
					return;
				}
				clearTimeout(this.sendAnswerTimeoutId);
				this.sendAnswerTimeoutId = null;
				this.$store.commit('setCurrentAnswer', this.currentAnswer.answerKey);
				this.showToast(this.$t('main.send-answer-ok'), 'success');
				if (this.smaMode) {
					this.moveNextQDef();
					// this.$refs.tb.scrollToCurrentQDef();
				}
				this.$store.commit('setIsSendingAnswer', false);
				this.disableResponsePanel = !this.currentQDef.allowMultipleChances
					&& !!(this.currentQDef.givenAnswer && this.currentQDef.givenAnswer.length > 0);
				break;
			case 'SFINISHED':
				this.disconnect();
				// this.showSFinishedModal = true;
				break;
			case 'MSG':
				if (message.messageContent.trim().length === 0) {
					this.welcomeInfo = null;
					this.showWelcome = true;
					return;
				}
				this.welcomeInfo = message.messageContent;
				break;
			case 'SMAINITEX':
				this.welcomeInfo = this.$t('main.loading-test');
				this.$store.commit('setCurrentTDef', new TestDefinition(message.messageContent));
				this.$store.commit('setCurrentQIndex', this.futureQIndex !== -1 ? this.futureQIndex : 0);
				this.$store.commit('setSmaMode', true);
				this.showCurrentQDef(this.$store.state.currentTDef.questions[this.currentQIndex]);
				this.showWelcome = false;
				break;
			case 'SMAEND':
				this.showWelcome = true;
				this.welcomeInfo = decodeURIComponent(message.messageContent);
				this.$store.commit('setSmaMode', false);
				break;
			default:
				break;
			}
		},
		post(type, content, msgCB) {
			const umid = btoa(Math.random()).slice(0, 10); // generate random id

			app2app.postMessage(this.$store.state.sessionId, type, content, umid, (res) => {
				this.$log.debug(res);

				if (type === 'DISCONNECT' && res.rsok && app2app.isConnected() && msgCB) {
					msgCB();
				}
			}, (error) => {
				this.$log.error(error);
				if (type === 'DISCONNECT' && msgCB) {
					msgCB();
				}
			});
		},
		showCurrentQDef(qdef) {
			if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				qdef = QuestionUtil.injectBase64Images(qdef);
			}
			this.$store.commit('setCurrentQDef', qdef);
			if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				this.$refs.rp.setCurrentQDef(this.currentTDef.questions[this.currentQIndex]);
				this.disableResponsePanel = false;
			} else if (this.$store.state.mode === this.mode.POLLING_SESSION) {
				this.$refs.rp.setCurrentQDef(qdef);
				this.disableResponsePanel = !this.currentQDef.allowMultipleChances
					&& !!(this.currentQDef.givenAnswer && this.currentQDef.givenAnswer.length > 0);
			}
		},
		moveToQDef(index) {
			if (index < 0 || index > this.currentTDef.questions.length - 1) {
				return;
			}
			if (this.$refs.rp.hasPendingAnswer()) {
				this.futureQIndex = index;
				this.showPendingAnswerModal = true;
				return;
			}
			this.futureQIndex = -1;
			this.$store.commit('setCurrentQIndex', index);
			this.showCurrentQDef(this.$store.state.currentTDef.questions[this.currentQIndex]);
			this.$refs.tb.scrollToCurrentQDef();
		},
		movePrevQDef() {
			this.moveToQDef(this.currentQIndex - 1);
		},
		moveNextQDef() {
			this.moveToQDef(this.currentQIndex + 1);
		},
		doRefresh() {
			this.futureQIndex = -1;

			this.$root.$emit('bv::hide::tooltip', 'refresh-tooltip');
			this.disableRefresh = true;

			this.welcomeInfo = null;
			this.showWelcome = true;

			this.post('REFRESH', this.$store.state.studentId);

			const timeoutId = setTimeout(() => {
				this.disableRefresh = false;
				clearTimeout(timeoutId);
			}, 10000);
		},
		doExit() {
			if (this.$store.state.mode === this.mode.POLLING_SESSION) {
				this.post('DISCONNECT', '');
				this.disconnect();
			} else if (this.$store.state.mode === this.mode.ONLINE_TEST) {
				clearInterval(this.autosaveIntervalId);
				this.autosaveIntervalId = null;
				if (this.$router.currentRoute.name !== 'Home') {
					this.$router.push({ name: 'Home' });
				}
			}
		},
		disconnect() {
			this.unregisterApp2AppMessages();
			if (this.$router.currentRoute.name !== 'Home') {
				this.$router.push({ name: 'Home' });
			}
			this.hideAllModals();
			app2app.disconnect();
		},
		sendAnswer(answer) {
			this.currentAnswer = answer;
			this.showConfirmAnswerModal = true;
		},
		doSendAnswer() {
			this.disableResponsePanel = true;
			this.showConfirmAnswerModal = false;

			this.$store.commit('setIsSendingAnswer', true);

			if (this.$store.state.sessionInfo.supportsAnswerConfirmation) {
				this.sendAnswerTimeoutId = setTimeout(() => {
					clearTimeout(this.sendAnswerTimeoutId);
					this.sendAnswerTimeoutId = null;
					this.showToast(this.$t('main.send-answer-error'), 'danger');
					this.disableResponsePanel = false;
					this.$store.commit('setIsSendingAnswer', false);
				}, 20000);
			}

			this.post(this.currentAnswer.answerCode, this.currentAnswer.answerKey);
		},
		doNotSendAnswer() {
			this.$refs.rp.clearAnswer();
			this.disableResponsePanel = false;
			this.showConfirmAnswerModal = false;
			this.$store.commit('setIsSendingAnswer', false);
		},
		discardPendingAnswer() {
			this.$refs.rp.clearAnswer();
			this.showPendingAnswerModal = false;
			this.moveToQDef(this.futureQIndex);
		},
		continuePendingAnswer() {
			this.futureQIndex = -1;
			this.showPendingAnswerModal = false;
		},
		showTestNavigationPopup() {
			this.showTestNavigationModal = true;
			const timeoutId = setTimeout(() => {
				this.$refs.ql.scrollToCurrentQDefMobile();
				clearTimeout(timeoutId);
			}, 300);
		},
		moveToQDefFromTestNavigation(index) {
			this.moveToQDef(index);
			this.showTestNavigationModal = false;
		},
		/* BEGIN - ONLINE TEST  */
		saveAnswer(answer) {
			this.currentAnswer = answer;
			this.showConfirmAnswerModal = true;
		},
		doSaveAnswer() {
			this.showConfirmAnswerModal = false;
			this.$store.commit('setCurrentAnswer', this.currentAnswer.answerKey);
			api.saveOnlineTestLocally();
			this.moveNextQDef();
			// this.$refs.tb.scrollToCurrentQDef();
		},
		submitOnlineTest() {
			// eslint-disable-next-line max-len
			api.submitOnlineTest(this.$store.state.onlineTestId, this.$store.state.studentId, this.$store.state.deviceId,
				(data) => {
					this.showSubmitTestModal = false;

					const status = XmlUtil.getValue(data, 'status');

					if (status === 'OK') {
						this.showToast(this.$t('main.submit-test-ok'), 'success');
						this.$store.commit('setIsOnlineTestSent', true);
						api.removeLocalOnlineTest(this.$store.state.onlineTestId);
						this.doExit();
					} else {
						let message = null;

						switch (status) {
						case 'INVALID HOMEWORK ID':
							message = this.$t('online-test.incorrect-id');
							break;
						case 'ALREADY ANSWERED':
							message = this.$t('online-test.answered');
							break;
						case 'EXPIRED HOMEWORK':
							message = this.$t('online-test.expired');
							break;
						default:
							message = this.$t('online-test.default-error');
							break;
						}

						this.showToast(message, 'danger');
					}
				},
				() => {
					this.showSubmitTestModal = false;
					this.showToast(this.$t('main.submit-test-error'), 'danger');
				});
		},
		confirmDiscardOnlineTest() {
			this.showExitModal = false;
			this.showConfirmDiscardTestModal = true;
		},
		confirmSaveLocallyOnlineTest() {
			this.showExitModal = false;
			this.showConfirmSaveTestModal = true;
		},
		saveLocallyOnlineTest() {
			api.saveOnlineTestLocally();
			this.hideAllModals();
			// this.showExitModal = false;
			this.doExit();
			this.showToast(this.$t('main.test-saved-locally'), 'success');
		},
		discardOnlineTest() {
			api.removeLocalOnlineTest(this.$store.state.onlineTestId);
			this.hideAllModals();
			// this.showExitModal = false;
			this.doExit();
		},
		/* END - ONLINE TEST */
		hideAllModals() {
			this.showExitModal = false;
			this.showSFinishedModal = false;
			this.showConfirmAnswerModal = false;
			this.showPendingAnswerModal = false;
			this.showTestNavigationModal = false;
			this.showSubmitTestModal = false;
			this.showConfirmDiscardTestModal = false;
			this.showConfirmSaveTestModal = false;
		},
		handleBackButton() {
			if (this.showExitModal) {
				this.$log.debug('showExitModal is open!');
				this.showExitModal = false;
			} else if (this.showSFinishedModal) {
				this.$log.debug('showSFinishedModal is open!');
				this.doExit();
			} else if (this.showConfirmAnswerModal) {
				this.$log.debug('showConfirmAnswerModal is open!');
				this.doNotSendAnswer();
			} else if (this.showPendingAnswerModal) {
				this.$log.debug('showPendingAnswerModal is open!');
				this.continuePendingAnswer();
			} else if (this.showTestNavigationModal) {
				this.$log.debug('showTestNavigationModal is open!');
				this.showTestNavigationModal = false;
			} else if (this.showSubmitTestModal) {
				this.$log.debug('showSubmitTestModal is open!');
				this.showSubmitTestModal = false;
			} else if (this.showConfirmSaveTestModal) {
				this.$log.debug('showConfirmSaveTestModal is open!');
				this.showConfirmSaveTestModal = false;
			} else if (this.showConfirmDiscardTestModal) {
				this.$log.debug('showConfirmDiscardTestModal is open!');
				this.showConfirmDiscardTestModal = false;
			} else {
				if (this.$refs.q.isExternalContentModalOpen()) {
					this.$log.debug('hideExternalContentModal() called!');
					this.$refs.q.hideExternalContentModal();
					return;
				}
				this.$log.debug('doExit() called!');
				this.doExit();
			}
		}
	},
	// eslint-disable-next-line no-unused-vars
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.$store.state.mode === vm.mode.POLLING_SESSION) {
				if (!app2app.isConnected()) {
					vm.$router.push({ name: 'Home' });
					return;
				}
				vm.$store.commit('setSmaMode', false);
				vm.$store.commit('setCurrentQIndex', -1);
				vm.$store.commit('setCurrentQDef', null);
				vm.$store.commit('setCurrentTDef', null);
				vm.$store.commit('setIsSendingAnswer', false);
				vm.registerApp2AppMessages();
				vm.processPendingApp2AppMessages();
				vm.updateNetworkStatus();
			} else if (vm.$store.state.mode === vm.mode.ONLINE_TEST) {
				if (!vm.$store.state.currentTDef) {
					vm.$router.push({ name: 'Home' });
					return;
				}
				vm.$store.commit('setSmaMode', true);
				vm.$store.commit('setCurrentQIndex', 0);
				vm.$store.commit('setIsSendingAnswer', false);

				vm.showCurrentQDef(vm.currentTDef.questions[vm.currentQIndex]);
				vm.showWelcome = false;

				vm.autosaveIntervalId = setInterval(() => {
					api.saveOnlineTestLocally();
				}, 300000);
			} else {
				vm.$router.push({ name: 'Home' });
			}
			native.getEventBus().$on('back-button', vm.handleBackButton);
		});
	},
	// eslint-disable-next-line no-unused-vars
	beforeRouteLeave(to, from, next) {
		if (this.$store.state.mode === this.mode.POLLING_SESSION) {
			this.unregisterApp2AppMessages();
			if (app2app.isConnected()) {
				this.post('DISCONNECT', '');
				app2app.disconnect();
			}
		}
		native.getEventBus().$off('back-button');
		next();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    line-height: 1.5;
}
.navbar-brand {
    margin-right: 5px;
}
.navbar-expand .navbar-nav {
    align-items: center;
}
.modal i {
    font-size: 5rem;
    color: #D5006C;
    margin-bottom: 20px;
}
.toast i {
    font-size: 1.4em;
}
.ev-phoneComponents-header .nav-link {
    display: contents;
}
.exit-icon-container {
    background-color: #E0E0E0;
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.exit-icon-container i {
    font-size: 40px;
    color: #231F20;
}
.close-icon-button {
    /* height: 12px !important;
    width: 12px !important; */
    padding: 0 !important;
    background-color: transparent !important;
    border: none;
}
.close-icon-button i {
    font-size: 18px;
    color: #231F20;
}
</style>
